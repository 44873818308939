import '../scss/main.scss';
import Faq from './faq.js';
import lottie from 'lottie-web';
import SmoothScroll from 'smooth-scroll';
const scroll = new SmoothScroll('a[href*="#"]', {
    offset: 70
});

const lottieContainer = document.getElementById('lottie');

if(lottieContainer) {
  lottie.loadAnimation({
    container: lottieContainer,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: '/dog-anim.json',
    useSubFrames: false
  });
}

/* NAVIGATION START */

const menuBtn = document.getElementById('btn-menu');
const closeBtn = document.getElementById('btn-closemenu');
const nav = document.getElementById('main-nav');
const navLinks = document.querySelectorAll('#main-nav ul li a');
const mobileTitle = document.querySelector('.mobile-header__title');
const subNavLinks = document.querySelectorAll('.js-subnav');

for(let i=0; i < subNavLinks.length; ++i) {
  let el = subNavLinks[i];
  let link = el.querySelector('.subnav-link');
  link.addEventListener('click', (e) => {
    e.preventDefault();
    let list = el.querySelector('ul');
    list.classList.add('visible');
  });
  el.addEventListener('mouseover', (e) => {
    let list = el.querySelector('ul');
    list.classList.add('visible');
  });
  el.addEventListener('mouseout', (e) => {
    let list = el.querySelector('ul');
    list.classList.remove('visible');
  });
}

menuBtn.addEventListener('click', (e) => {
  e.preventDefault();
  nav.classList.add('visible');
});
closeBtn.addEventListener('click', (e) => {
  e.preventDefault();
  nav.classList.remove('visible');
});

for(let i=0; i < navLinks.length; i++) {
  navLinks[i].addEventListener('click', (e) => {
    nav.classList.remove('visible');
  });
}

mobileTitle.addEventListener('click', (e) => {
  e.preventDefault();
  window.scrollTo(0,0);
});

window.addEventListener('scroll', (e) => {
  if(window.scrollY > 200) {
    document.body.classList.add('is-scrolled');
  }else{
    document.body.classList.remove('is-scrolled');
  }
});

/* NAVIGATION END */

const mediaMoreBtn = document.getElementById('media-showmore');

if(mediaMoreBtn) {
  const hiddenArticles = document.querySelectorAll('.row--media.row--hidden');
  mediaMoreBtn.addEventListener('click', (e) => {
    e.preventDefault();
    mediaMoreBtn.remove();
    for(let i = 0; i < hiddenArticles.length; i++) {
      hiddenArticles[i].classList.remove('row--hidden');
    }
  });
}

if(document.body.classList.contains('faq')) {
  const faq = new Faq();
}