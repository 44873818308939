import axios from 'axios';
import anchorme from 'anchorme';

export default class Faq {
  constructor() {
    const lang = (typeof craftLang !== 'undefined') ? craftLang : 'de';
    this.el = document.querySelector('.faq__content');
    this.el.innerHTML = 'Loading Data...';

    axios.get('https://shsapi.azurewebsites.net/api/faq/public', {
      headers: {
        'Accept-Language': lang
      }
    })
    .then((data) => {
      this.faqData = data.data;
      this.insertFaq();
    })
    .catch((error) => {
      this.el.innerHTML = 'Error - could not load FAQ data.';
      console.log(error);
    });
  }
  insertFaq() {
    let faqHtml = '';
    this.faqData.forEach(item => {
      faqHtml += `<h3>${item.title}</h3>`;
      let txt = item.text;
      txt = anchorme({
        input: txt,
        options: {
          attributes: {
            target: 'blank'
          }
        }
      });
      faqHtml += `<p>${txt}</p>`;
    });
    this.el.innerHTML = faqHtml;
  }
}